import { Observable } from 'rxjs';
import type { ErrorObject } from 'ajv';
import type { google as googleMaps } from 'google-maps';

// eslint-disable-next-line no-var
declare var google: googleMaps;

export enum TypesOfShippings {
  TAKE_AWAY = 'TAKE_AWAY',
  DELIVERY = 'DELIVERY',
  IN_PLACE = 'IN_PLACE',
}

export interface FirebaseUser {
  uid: string;
  getIdTokenResult: () => Promise<{
    token: string;
    claims: {
      [key: string]: any;
    };
  }>;
}

export interface User {
  uid: string;
  claims: {
    appSuperAdmin?: boolean;
  };
}

export interface Establishment {
  id: string;
  created?: Date; // NOTE: this was added on 2024 and no backfill was used for old data
  updated?: Date; // NOTE: this was added on 2024 and no backfill was used for old data
  logo: string;
  name: string;
  slug: string;
  userId: string;
  phone: string;
  additionalPhone?: string;
  image?: string; // for subsidiaries
  theme?: EstablishmentTheme;
  subsidiaries: string[];
  cloneTargets?: string[];
  enableCoupons: boolean;
  minimumPurchase: number;
  // @deprecated
  address: string;
  addressDetails?: AddressDetails;
  addressReferences?: string;
  currencyCode: CurrencyCode;
  altLanguage: ALT_LANGUAGES;
  categories: string[];
  isDigitalMenu: boolean;
  countryCode?: Country;
  countryDetail?: string;
  contactInfo: ContactInfo;
  subscription?: SubscriptionInfo;
  workingDays?: WorkingDays;
  temporaryClosure?: TemporaryClosure;
  facebookPixel?: string;
  googleAnalytics?: string;
  googleTagManager?: string;
  facebookDomainVerification?: string;
  seoSocialMedia?: EstablishmentSeoSocialMedia;
  features: Feature[];
  links?: {
    website: string;
    instagram: string;
    facebook: string;
    twitter: string;
  };
  productsExport?: EstablishmentProductsExport;
  subdomainStatus?: 'PENDING' | 'ACTIVE' | 'INACTIVE';
  subdomainRedirection?: boolean;
  customDomain?: CustomDomain;
  redirectTo?: string;
  catalogConfiguration: CatalogConfiguration;
  announcements?: Announcements;
  // Food Bank Donation
  enableFoodBankDonation?: boolean;
  facebookCatalog?: {
    catalogId: string;
    productSetId: string;
    customConversionId: string;
    hasCatalog: boolean;
    lastExecution: Date;
    error: string;
    catalogUrl: string;
  };
}

export type EstablishmentCheckout = {
  establishmentId: string;
  deliveryCost?: number;
  shippingOptions: TypesOfShippings[];
  requireAddressDetails: boolean;
  outOfDeliveryZoneShippingAllowed?: boolean;
  deliveryType?: DeliveryType;
  deliveryZones?: DeliveryZone[];
  paymentMethods: PaymentMethod[];
  customFields?: CustomField[];
  created: Date;
  updated: Date;
};

export type Announcements = {
  title?: string;
  body?: string;
};

export type CatalogConfiguration = {
  displaySku: boolean;
  skuInOrderMessage: boolean;
};

export interface EstablishmentProductsExport {
  enabled: boolean;
  sources: ProductsExportSource[];
}

export interface ProductsExportSource {
  type: ProductsExportSourceType;
  enabled: boolean;
  lastExecutionDate: Date | any;
  status: ProductsExportSourceStatus;
  error: string;
  url: string;
}

export enum ProductsExportSourceStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export type ProductsExportSourceType = 'instagram';

export interface ExportJob {
  establishmentId: string;
  establishmentSlug: string;
  type: ProductsExportSourceType;
  createdAt: Date;
}

export type Feature =
  | 'ADDRESS_IN_MAP'
  | 'DELIVERY_ZONES'
  | 'PRODUCTS_EXPORT'
  | 'ORDER_LIST_DETAILS'
  | 'PRODUCT_PRESENTATIONS'
  | 'PRODUCT_OPTIONS'
  | 'PRODUCT_GROUPS'
  | 'THEME'
  | 'QR_CODE'
  | 'DIGITAL_MENU'
  | 'COUPONS'
  | 'SEO_SOCIAL_MEDIA'
  | 'SLUG_CUSTOMIZATION'
  | 'FACEBOOK_PIXEL'
  | 'GOOGLE_ANALYTICS'
  | 'GOOGLE_TAG_MANAGER'
  | 'CHECKOUT_CUSTOM_FIELDS'
  | 'WORKING_HOURS'
  | 'STATISTICS_EXPLORE_RESULTS'
  | 'SUBDOMAIN'
  | 'IMAGE_GALLERY'
  | 'IMAGE_GALLERY_EXTENDED'
  | 'PRODUCT_ITEM_IMAGES'
  | 'WHITE_LABEL_BRANDING'
  | 'INTEGRATIONS'
  | 'ALT_LANGUAGE'
  | 'STOCK_CONTROL'
  | 'BULK_EDIT'
  | 'PRODUCTS_IMPORT'
  | 'SKU'
  | 'CATEGORY_AVAILABILITY'
  | 'ANNOUNCEMENTS'
  | 'ADVANCED_STATISTICS'
  | 'THERMAL_PRINTING'
  | 'ADS'
  | 'PRODUCT_TAGS'
  | 'DYNAMIC_CATEGORIES';

export enum DeliveryType {
  FIXED = 'fixed',
  DELIVERY_ZONES = 'delivery-zones',
}

export type DeliveryZoneType = 'circle' | 'polygon';
export interface DeliveryZoneBase {
  cost: number;
  minimumPurchase?: number;
  color: string;
  type: DeliveryZoneType;
}
export interface DeliveryZoneCircle extends DeliveryZoneBase {
  type: 'circle';
  center: { lat: number; lng: number };
  radius: number;
}
export interface DeliveryZonePolygon extends DeliveryZoneBase {
  type: 'polygon';
  paths: { lat: number; lng: number }[];
}

export type DeliveryZone = DeliveryZoneCircle | DeliveryZonePolygon;
export type CustomFieldType = 'text' | 'number' | 'date' | 'single-choice' | 'multiple-choice';
export type CustomFieldOption = { value: string };

export interface CustomField {
  fieldType: CustomFieldType;
  name: string;
  legend?: string;
  required?: boolean;
  min?: number;
  max?: number;
  defaultValue?: number | string;
  options?: CustomFieldOption[];
}

export interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
  toDate: () => Date;
}

export interface CustomFieldDetail {
  field: CustomField;
  value: string | number | Date | FirestoreTimestamp | string[];
}

export interface EstablishmentSeoSocialMedia {
  shortDescription?: string;
  description?: string;
  keywords?: string[];
  image?: string;
  shortImage?: string;
  displayShareButton?: boolean;
  // twitterUser?: string;
}

export type PaymentGateway = 'STRIPE' | 'BOXFUL' | 'OFFLINE' | 'ZENRISE';

export interface SubscriptionInfo {
  id: string;
  plan: string; // TODO: this should be typed PlayType
  createdAt: Date;
  updatedAt: Date;
  canceledAt?: Date | null;
  canceledByUser?: boolean;
  currentPeriodStartDate: Date | null;
  currentPeriodEndDate: Date | null;
  trialPeriodStartDate?: Date | null;
  trialPeriodEndDate?: Date | null;
  paymentGateway: PaymentGateway;
  isTrial: boolean;
  requiresPayment: boolean;
  lastPaymentError?: {
    message: string;
    code: string;
    /**
     * Bank error code
     */
    declineCode?: string;
  };
  status: SubscriptionStatus;
  customerId: string;
  countryCode?: Country;
  lastUpgrade?: Date;
  billingType?: BillingType;
  promotionCode?: string;
  requiresOnboarding?: boolean;
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
}

export interface ContactInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  discoverySource?: string;
}

export type PaymentMethodPriceModifier = 'none' | 'extraCharge' | 'discount';

export interface PaymentMethod {
  removable: boolean;
  name: string;
  priceModifier: PaymentMethodPriceModifier;
  extraCharge?: number;
  discount?: number;
  active: boolean;
  type: PaymentMethodType;
  hasLink?: boolean;
  paymentLinkAvailable?: boolean;
  legend?: string;
  countryAvailables?: Country[];
}

export enum PaymentMethodType {
  CASH = 'CASH',
  MERCADOPAGO = 'MERCADOPAGO',
  CARD = 'CARD',
  UALA_LINK = 'UALA_LINK',
  MERCADOPAGO_LINK = 'MERCADOPAGO_LINK',
  ZENRISE_LINK = 'ZENRISE_LINK',
}

export interface EstablishmentTheme {
  color1?: string;
  colorContrast1?: string;
  color2?: string;
  colorContrast2?: string;
  color3?: string;
  color4?: string;
}

export interface WorkingDays {
  isActive: boolean;
  enableOrdersOnClosed: boolean;
  selectedDaysOfWeek: (keyof WorkingDays['daysOfWeekConfig'])[];
  customizeHoursByDay: boolean;
  primaryWorkingHours?: TimeRange;
  secondaryWorkingHours?: TimeRange;
  daysOfWeekConfig: {
    1: WorkingHours;
    2: WorkingHours;
    3: WorkingHours;
    4: WorkingHours;
    5: WorkingHours;
    6: WorkingHours;
    7: WorkingHours;
  };
}

export type TemporaryClosure = {
  mode: 'now' | 'schedule' | 'disabled';
  from?: Date;
  to?: Date;
  message?: string;
  allowCatalogBrowsing?: boolean;
};

export interface WorkingHours {
  primaryWorkingHours: TimeRange;
  secondaryWorkingHours: TimeRange;
}

export interface TimeRange {
  from?: number;
  to?: number;
}

export type ProductGroupingStrategy = 'category' | 'product-group' | 'none';

export type Category = WithAltLanguages<
  {
    id: string;
    added: Date;
    updated: Date;
    establishmentId: string;
    image?: string;
    name: string;
    active: boolean;
    sortOrder: number;
    hideLabelInList?: boolean;
    availability: {
      isActive: boolean;
      isHidden: boolean;
      daysOfWeek: number[];
      timeRange: TimeRange;
    };
    isDynamic: boolean;
    dynamicGrouping?: ProductGroupingStrategy;
    linkedProductTagIds?: string[];
    productsOrder?: string[];
    imageToClone?: ImageToClone;
    clonedFrom?: string;
  },
  'name'
>;

export type Product = WithAltLanguages<
  {
    id: string;
    added: Date;
    updated: Date;
    categoryId: string;
    establishmentId?: string;
    description?: string;
    name: string;
    sku?: string;
    price: number;
    priceDiscount?: number;
    sortOrder: number;
    stockQty: number | null; // null is equal to "Infinite"; 0 is without stock; any number means finite stock
    hidden?: boolean;
    presentations?: OptionConfiguration | null;
    options?: OptionConfiguration[];
    linkedOptionIds?: string[]; // This is the list of Shared Options linked with this Product
    brand?: string;
    link?: string;
    categoryName?: string;
    groupName?: string;
    images: string[];
    linkedProductTagIds: string[];
    primaryTagId?: string;
    imagesToClone?: ImageToClone[];
    clonedFrom?: string;
    isOptionItem?: boolean;
  },
  'name' | 'description' | 'groupName'
>;

export type ImageToClone = {
  srcPath: string;
  destPath: string;
};

export type ProductListItemDTO = Product & {
  searchableName: string;
  listIndex: number;
  isUpdatingImages: boolean;
};

export interface SharedOption {
  id?: string;
  establishmentId: string;
  linkedItemIds: string[];
  sortOrder: number;
  details: OptionConfiguration;
  clonedFrom?: string;
}

export type SharedOptionWithItems = {
  sharedOption: SharedOption;
  linkedItems: Product[];
};

export type OptionConfiguration = WithAltLanguages<
  {
    id: string;
    name: string;
    rangeType: ProductOptionRangeType;
    min: number;
    max?: number;
    displayItemQuantities?: boolean;
    items?: ProductOptionItem[];
    sortOrder?: number;
    created?: Date;
    updated?: Date;
  },
  'name'
>;

export enum ProductOptionRangeType {
  ANY = 'any',
  FIXED = 'fixed',
  RANGE = 'range',
}

export type ProductOptionItem = WithAltLanguages<
  {
    id: string;
    name: string;
    sku?: string;
    isOptionItem?: boolean;
    image?: string;
    price: number;
    priceDiscount?: number;
    maxSelection?: number;
    quantity?: number;
    description?: string;
    stockQty: number | null; // null is equal to "Infinite"; 0 is without stock; any number means finite stock
    sortOrder?: number;
  },
  'name' | 'description'
>;

export type CartItemCategory = Pick<
  Category,
  'id' | 'establishmentId' | 'image' | 'name' | 'availability'
>;
export type CartItemProduct = Pick<
  Product,
  | 'id'
  | 'categoryId'
  | 'establishmentId'
  | 'description'
  | 'name'
  | 'sku'
  | 'price'
  | 'priceDiscount'
  | 'stockQty'
  | 'groupName'
  | 'images'
  | 'linkedProductTagIds'
  | 'presentations' // NOTE: this shouldn't be necessary but is needed by some dependency during purchase process
>;

export interface CartItem {
  id: string;
  product: CartItemProduct;
  category: CartItemCategory;
  selectedPresentation?: CartItemOptionItems;
  selectedOptions?: CartItemOptionItems[];
  observations: string;
  qty: number;
}

export interface CartItemOptionItems {
  option: OptionConfiguration;
  items: ProductOptionItem[];
}

export interface PersonalInformation {
  name: string;
  address: Address;
  phone: string;
}

export interface CustomAddressComponents {
  freeSearch?: string;
}

export interface Address {
  street: string;
  addressDetails: AddressDetails;
  references: string;
  isAFlat: boolean;
  floorAndNumber: string;
}

export interface AddressDetails {
  freeSearch?: string;
  street: string;
  streetNumber: string;
  neighborhood?: string;
  state?: string;
  locality?: string;
  country?: string;
  postalCode?: string;
  fullAddress: string;
  coordinates?: google.maps.LatLngLiteral;
  useCustomLocation?: boolean;
  url?: string;
  timezone?: string;
  resultType?:
    | 'locality'
    | 'administrativeArea'
    | 'houseNumber'
    | 'intersection'
    | 'street'
    | 'place';
}

export interface ShippingOption {
  name: string;
  type: TypesOfShippings;
}

export type EndOrderDetailPaymentStatus =
  | 'PENDING'
  | 'PAID'
  | 'REFUNDED'
  | 'REJECTED'
  | 'CANCELLED';

export type EndOrderDetailPaymentLink = {
  url: string;
  status: EndOrderDetailPaymentStatus;
  id?: string;
  updated?: Date;
};

// a.k.a ordersnapshot
export type EndOrderDetails = {
  id?: string;
  sequenceId: number;
  status: OrderStatus;
  created: Date;
  updated: Date;
  establishmentId: string;
  personalInformation: PersonalInformation;
  cartItems: CartItem[];
  totalAmount: number;
  totalAmountWithDiscount: number;
  totalProductDiscount: number;
  deliveryCost: number;
  couponDiscount?: number;
  paymentMethodExtraCharge?: number;
  paymentMethodDiscount?: number;
  finalAmount?: number;
  couponConfigurationSnapshot?: CouponConfigurationResponse;
  // @deprecated - payment link is generated dynamically on the BE
  customFieldDetails?: CustomFieldDetail[];
  outOfDeliveryZone?: boolean;
  paymentMethod?: PaymentMethod;
  shippingOption?: ShippingOption;
  tableNumber?: string;
  howMuch?: number;
  endUserUid?: string;
  cancelReason?: OrderCancelReason;
  cancelReasonDetails?: string;
  cancelDate?: Date;
  internalNotes?: string;
  tags: string[];
  retries?: number;
  paymentLink?: EndOrderDetailPaymentLink;
  // Food Bank DOnation
  foodBankDonationAmount?: number;
  /*
    5: adds sequenceId
    6: adds endUserUid
    7: adds status, cancelReason, cancelReasonDetails, tags, internalNotes
  */
  schemaVersion: number;
};

export type EndUser = {
  uid: string;
  isAnnonymous: boolean;
  created: Date;
  updated: Date;
  details: PersonalInformation;
};

export type EndOrderDetailsSessionData = {
  updatedAt: Date;
  details: Pick<
    EndOrderDetails,
    | 'paymentMethod'
    | 'shippingOption'
    | 'tableNumber'
    | 'howMuch'
    | 'customFieldDetails'
    | 'couponConfigurationSnapshot'
  >;
};

export interface Order {
  id?: string;
  sequenceId: number;
  status: OrderStatus;
  establishmentId: string;
  totalAmount: number;
  phone: number;
  date: Date;
  updated: Date; // NOTE: this was added after ~2024-03-15, so this field is null in Orders prior to this change
  address: string;
  paymentMethod: string;
  shippingOption?: string;
  name: string;
  schemaVersion: number;
  addressDetails: AddressDetails;
  tags?: string[];
  internalNotes?: string;
  cancelReason?: OrderCancelReason;
  cancelReasonDetails?: string;
  paymentLink?: EndOrderDetailPaymentLink;
}

export const orderStatuses = ['new', 'in-progress', 'completed', 'canceled'] as const;
export type OrderStatus = (typeof orderStatuses)[number];

export const orderCancelReasons = [
  'CUSTOMER_CANCELATION',
  'PRODUCTS_UNAVAILABLE',
  'WRONG_ADDRESS',
  'PAYMENT_ERROR',
  'DUPLICATED',
  'OTHER',
] as const;
export type OrderCancelReason = (typeof orderCancelReasons)[number];

export type OrdersSequence = {
  lastValue: number;
  createdAt: Date;
  updatedAt: Date;
};

export interface DateRange {
  from: Date;
  to: Date;
}

export interface StatisticsSummary {
  numOfSales: number;
  salesAmount: number;
  averageTicketAmount: number;
  uniqueCustomers: number;
  paymentMethod: {
    name: string;
    amount: number;
  };
  shippingOption: {
    name: string;
    amount: number;
  };
}

export enum CouponDiscountType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export interface CouponConfiguration {
  id: string;
  name: string;
  used: number;
  code: string;
  validFrom: Date;
  validTo: Date;
  active: boolean;
  discountType: CouponDiscountType;
  discount: number;
  quantity?: number;
  minAmount?: number;
  paymentMethods: string[] | ['ALL'];
  daysOfWeek: number[] | ['ALL'];
  categories: string[] | ['ALL'];
  productTags: string[];
  added: Date;
  updated: Date;
}

export interface CouponUsageRegistry {
  user: {
    name: string;
    phone: string;
  };
  date: Date;
  discountAmount: number;
  orderAmount: number;
}

export type CouponConfigurationResponse = Pick<
  CouponConfiguration,
  | 'code'
  | 'discountType'
  | 'discount'
  | 'daysOfWeek'
  | 'categories'
  | 'productTags'
  | 'paymentMethods'
  | 'minAmount'
  | 'updated'
>;

export interface PrivateConfigurationPaymentLink {
  clientId: string | null;
  secretId: string | null;
}
export interface PrivateConfiguration {
  mercadopagoToken: string | null;
  zenriseConfig: PrivateConfigurationPaymentLink | null;
  ualaConfig: UalaConfig | null;
}

export enum StatisticsType {
  NUM_OF_SALES = 'numOfSales',
  SALES_AMOUNT = 'salesAmount',
  AVERAGE_TICKET_AMOUNT = 'averageTicketAmount',
  UNIQUE_CUSTOMERS = 'uniqueCustomers',
  PAYMENT_METHODS = 'paymentMethod',
  SHIPPING_OPTIONS = 'shippingOption',
}

export type StatisticsTypes = 'basic' | 'advanced';

export type StatisticsAdvancedTypes = 'orders' | 'orderDetails' | 'googleAnalytics';

export interface FunctionResponseOK<T> {
  result: T;
}

export interface FunctionResponseError {
  error: {
    status: string;
    message: string;
    details?: any;
  };
}

export type FunctionResponse<T = any> = FunctionResponseOK<T> | FunctionResponseError;
export type FunctionResponseClient<T = any> = T | FunctionResponseError;

export interface ValidateCouponParams {
  establishmentId: string;
  couponCode: string;
}

export interface UseCouponParams {
  couponCode: string;
  establishmentId: string;
  userName: string;
  userPhone: string;
  couponDiscount: number;
  orderAmount: number;
}

export interface RegisterData {
  name: string;
  slug: string;
  username: string;
  password: string;
}

export const CURRENCY_CODES = [
  'ARS',
  'BOB',
  'BRL',
  'BSD',
  'CAD',
  'CLP',
  'COP',
  'CRC',
  'CUP',
  'DOP',
  'EUR',
  'GTQ',
  'GYD',
  'HNL',
  'JMD',
  'MXN',
  'NIO',
  'PAB',
  'PEN',
  'PYG',
  'USD',
  'UYU',
  'VES',
] as const;
export type CurrencyCode = (typeof CURRENCY_CODES)[number];
export type Country = 'AR' | 'CL' | 'MX' | 'PE' | 'UY' | 'CO' | 'BR' | 'ES' | 'XX';
export type LocaleID =
  | 'es-AR'
  | 'es-CL'
  | 'es-MX'
  | 'es-PE'
  | 'es-UY'
  | 'es-CO'
  | 'pt'
  | 'es'
  | 'es-419'
  | 'en-US';
export type CurrencyLocaleData = {
  currencyCode: CurrencyCode;
  currencySymbol: string;
  decimalsLength: number;
  thousandsSeparator: '.' | ',' | ' ';
  decimalSeparator: '.' | ',';
  excelNumberFormat: string;
};

export type REPORT_TYPE = 'ORDERS' | 'PRODUCTS';

export interface Customer {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface RegistrationData {
  id?: string;
  created?: Date;
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    dni?: string;
    discoverySource: string;
  };
  establishment: {
    name: string;
    slug: string;
    phone: string;
    countryCode: Country;
    countryDetail: string;
    categories: string[];
    cuit?: string;
    businessName?: string;
    ivaCondition?: string;
  };
  account: {
    username: string;
    password: string;
    email?: string;
  };
  seller?: string;
  couponId?: string;
  promotionCode?: string;
  planType?: PlanType;
  billingType: BillingType;
  acceptTermsAndConditions?: boolean;
}

export interface StripePromotionCode {
  discount: number;
  recurring_profit: number;
  couponId: string;
}

export interface StripeSubscriptionParams {
  registrationData: Partial<RegistrationData>;
  priceId: string;
  paymentMethodId: string;
  planType: PlanType;
}

export interface StripeSubscriptionResponse {
  subscription: any;
}

export interface StripeCancelSubscriptionParams {
  establishmentId: string;
  subscriptionId: string;
}

export interface StripeCancelSubscriptionResponse {
  result: boolean;
}

export interface MercadopagoLinkResponse {
  mercadopagoLink: string;
}

export interface MercadopagoLinkParams {
  establishmentId: string;
  totalAmount: string;
}

export interface BoxfulCustomer {
  id?: number;
  fname: string;
  lname: string;
  email: string;
  phone: number;
  custom_fields: BoxfulCustomFields[];
}

export interface BoxfulCustomFields {
  name: string;
  field_type: string;
  content_string: string;
}

export interface BoxfulCustomerCreateResponse {
  loginToken: string;
}

export interface BoxfulCreateCustomerParams {
  boxfulCustomer: BoxfulCustomer;
}

export interface BoxfulWebhook {
  subscription: BoxfulSubscription;
  customer: BoxfulCustomer;
}

interface BoxfulSubscription {
  object: string;
  id: number;
  customer_id: number;
  status: string;
  payment_type: string;
  created_at: string;
  updated_at: string;
  amount: number;
  currency: string;
  coupon_id?: any;
  plan_id: number;
  delivery_price_item_id: number;
  external_service: string;
  metadata?: any;
  authorized_at?: any;
  cancelled_at: string;
  cbu?: any;
  last_invoice: Lastinvoice;
  next_invoice?: any;
}

interface Lastinvoice {
  object: string;
  id: number;
  status: string;
  amount: number;
  due_date: string;
  currency: string;
}

export interface ZenriseCancelSubscription {
  establishmentId: string;
  subscriptionId: string;
}

export interface ZenriseSubscriptions {
  subscriptions: ZenriseSubscription[];
}

export type ZenrisePaymentLink = {
  amount: number;
  contact: {
    firstName: string;
  };
  description: string;
  firstDueDate: string;
  percentageAfterFirstDueDate: number;
  secondDueDate: string;
  percentageAfterSecondDueDate: number;
  sendPerEmail: boolean;
  backUrl: {
    success: string;
    failure: string;
  };
};

interface ZenriseSubscription {
  contact: ZenriseSubscriptionContact;
  externalReference: string;
  startDate: string;
  paymentToken: ZenriseSubscriptionPaymentToken;
  collectType: string;
  planId?: any;
  creditCard: number;
  metadata: {
    registrationData: RegistrationData;
  };
}

interface ZenriseSubscriptionPaymentToken {
  bin: string;
  token: string;
  lastFourDigits: string;
}

interface ZenriseSubscriptionContact {
  email: string;
  firstName: string;
  lastName: string;
  holderDocumentNumber: string;
  phoneAreaCode: string;
  phoneNumber: string;
  externalReference: string;
}

export interface ZenriseWebhook {
  event: string;
  object: ZenriseWebhookObject;
}

export interface ZenriseWebhookObject {
  id: number;
  status: string;
  description?: any;
  subscriptionConfiguration: ZenriseWebhookConfiguration;
  contact: ZenriseWebhookContact;
  metadata: any;
  billingDates: ZenriseWebhookBillingDate;
}

interface ZenriseWebhookContact {
  id: number;
  externalReference: string;
}

interface ZenriseWebhookConfiguration {
  id: number;
  startDate: string;
  periods: number;
  amountAfterFirstDueDate?: any;
  amountAfterSecondDueDate?: any;
  firstDuePercentage: number;
  secondDuePercentage: number;
  endDate: string;
  lastFourDigits: string;
  collectType: string;
  chargeType: string;
  daysAfterFirstDue: number;
  daysAfterSecondDue: number;
}

interface ZenriseWebhookBillingDate {
  startBillingDate: string;
  nextBillingDate: string;
}

export interface AdminPanelOption {
  type: AdminPanelOptionType;
  icon: string;
  url: string;
  isVisible: boolean;
  label: string;
  class?: string;
}

export type AdminPanelOptionType =
  | 'catalogue'
  | 'general'
  | 'subscription'
  | 'theme'
  | 'seoSocialMedia'
  | 'workingHours'
  | 'paymentMethods'
  | 'shippingOptions'
  | 'checkout'
  | 'qrCode'
  | 'coupons'
  | 'statistics'
  | 'orderList'
  | 'analytics'
  | 'instagramShopping'
  | 'integrations'
  | 'clonning'
  | 'announcements'
  | 'ads';

export type AdminPanelLinksNavigation =
  | 'subscriptionInfo'
  | 'youtube'
  | 'web'
  | 'instagram'
  | 'subscriptionUpgrade'
  | 'helpCenter';

export type AdminPanelHeaderNavigation = 'settings' | 'previewMode' | 'notifications';

export enum PlanType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
  CUSTOM = 'CUSTOM',
}
export interface ProrationRequest {
  establishmentId: string;
  targetPlan: PlanType;
  prorationDate?: number;
  billingType: BillingType;
}

export interface UpdateSubscriptionRequest {
  establishmentId: string;
  targetPlan: PlanType;
  currentPlan: PlanType;
  customerId: string;
  billingType: BillingType;
  prorationDate?: number;
  paymentMethodId?: string;
  startNow?: boolean;
}

export interface Plan {
  description?: string;
  name?: string;
  prices?: any;
  features?: string[];
  amounts?: any;
}

export interface ProrationResponse {
  result: ProrationAmount;
}

export interface ProrationAmount {
  unusedCurrentPlanAmount: number;
  differenceToPayForNewPlan: number;
  targetPlanPrice: number;
  remainingDays?: number;
}

export interface ZenriseUpdateSubscriptionRequest {
  subscriptionId: number;
  planId: number;
  startNow: boolean;
}

export interface ZenriseProrationResponse {
  prorationAmount: number;
  transcurredDays: number;
  remainingDays: number;
  currentPrice: number;
  newPrice: number;
  type: string;
}

export type PlanFeature = {
  name: string;
  planDetails: {
    [key: string]: PlanDetails;
  };
};

export type PlanDetails = {
  display: boolean;
  text?: string;
  custom?: boolean;
  config?: any;
};

export interface PasswordRecoveryRequest {
  id?: string;
  date: Date;
  establishmentSlug: string;
  registrationEmail: string;
  dateUsed: Date;
}

export type AppRequestUrl = {
  hostname: string;
  pathname: string;
};

export interface CancelSubscriptionReason {
  values: string[];
  comment?: string;
  slug: string;
  establishmentId: string;
}

export interface CancelSubscriptionReasonItem {
  name: string;
  value: string;
  isRequired: boolean;
}

export type BillingType = 'MONTLY' | 'YEARLY';

export interface StripePromotionCode {
  discount: number;
  recurring_profit: number;
  couponId: string;
}

export interface StripePromotionCodeSuccess {
  couponId: string;
  promotionCode: string;
}

export interface LandingPromotions {
  startDate: Date;
  endDate: Date;
  promotionCode: string;
  codes: LandingPromotionsCode[];
}

export interface LandingPromotionsCode {
  billingType: BillingType;
  code: string;
  discount: number;
  quantityOfMonths: number;
}

export type SortInput = { id: string; sortOrder: number };

export type IntegrationProvider = 'maxirest' | 'fudo' | 'thinkion' | 'mrc';

export type Integration = {
  id?: string;
  active: boolean;
  establishmentId: string;
  provider: IntegrationProvider;
  operations: string[];
  details: {
    created: Date;
    updated: Date;
    accountDetails: Record<string, string>;
  };
};

export type PubSubIntegrationMessage<T> = {
  attributes: {
    provider: string;
    eventType: 'create' | 'update' | 'delete';
    topic: string;
    documentId: string;
    establishmentId: string;
  };
  json: {
    accountDetails: Record<string, string>;
    beforeData?: T;
    afterData: T;
    integration: IntegrationRelation;
  };
};

export type CustomDomain = {
  domain: string;
  provider: string;
};

export type IntegrationRelation = {
  id?: string;
  provider: string;
  establishmentId: string;
  relations: IntegrationRelationType;
};

export type IntegrationRelationTypes = 'paymentMethods' | 'products';

export type IntegrationRelationType = {
  paymentMethods: IntegrationRelationItem[];
  products: IntegrationRelationItem[];
};

export type IntegrationRelationItem = {
  localId: string;
  externalId: string | number;
};

export type FudoOrder = {
  order: FudoOrderContent;
};

type FudoOrderContent = {
  comment: string;
  customer: FudoOrderCustomer;
  discounts: FudoOrderDiscount[];
  externalId: string | number;
  items: FudoOrderItem[];
  payment: FudoOrderPayment;
  type: string;
  typeOptions: FudoOrderTypeOptions;
};

type FudoOrderTypeOptions = {
  address: string;
};

type FudoOrderPayment = {
  paymentMethod: { id: number };
  total: number;
};

export type FudoOrderItem = {
  comment: string;
  quantity: number;
  price: number;
  product: FudoOrderProduct;
  subitems?: FudoOrderSubitem[];
};

export type FudoOrderSubitem = {
  productId: number;
  productGroupId: number;
  quantity: number;
  price: number;
};

type FudoOrderProduct = {
  id: number;
};

type FudoOrderDiscount = {
  amount: number;
};

type FudoOrderCustomer = {
  phone: string;
  name: string;
};

export type IntegrationOrderProductOptionItem = {
  id: string;
  price: number;
  quantity?: number;
  name: string;
};

type IntegrationOrderOptionConfiguration = {
  id: string;
};

export type IntegrationOrderCartItemOptionItems = {
  option: IntegrationOrderOptionConfiguration;
  items: IntegrationOrderProductOptionItem[];
};

type IntegrationOrderPersonalInformation = {
  name: string;
  phone: string;
  address: string;
};

type IntegrationOrderCoupon = {
  discount: number;
  code: string;
};

export type IntegrationOrderCartItem = {
  id: string;
  productId: string;
  productName: string;
  price: number;
  selectedPresentation: IntegrationOrderCartItemOptionItems;
  qty: number;
  details: string;
  observations: string;
  selectedOptions: IntegrationOrderCartItemOptionItems[];
};

export type IntegrationOrder = {
  id: string | number;
  created: Date;
  establishmentId: string;
  personalInformation: IntegrationOrderPersonalInformation;
  totalAmount: number;
  finalAmount: number;
  howMuch: number;
  deliveryCost: number;
  observations: string;
  paymentMethod: PaymentMethodType | string;
  cartItems: IntegrationOrderCartItem[];
  isDelivery: boolean;
  coupon: IntegrationOrderCoupon;
};

export interface IntegrationItem {
  name: string;
  id: string | number;
}

export interface IntegrationProviderService {
  getCatalog: (establishmentId: string) => Observable<any>;
  getProducts: () => IntegrationItem[];
  getPresentations: () => IntegrationItem[];
  getOptions: () => IntegrationItem[];
  getProviderName: () => IntegrationProvider;
  requiresPaymentMethodConfiguration: boolean;
}

export interface FudoCatalog {
  active: boolean;
  description?: any;
  id: number;
  name: string;
  price: number;
  productCategoryId: number;
  productGroups: FudoCatalogProductGroup[];
  image?: any;
  enableOnlineMenu?: boolean;
  enableQrMenu?: any;
  sellAlone: boolean;
}

interface FudoCatalogProductGroup {
  id: number;
  maxQuantity: number;
  minQuantity: number;
  name: string;
  productGroupProducts: FudoCatalogProductGroupProduct[];
}

interface FudoCatalogProductGroupProduct {
  maxQuantity: number;
  price: number;
  productId: number;
}

export const LANGUAGE_COOKIE = 'pedix-language';
export const LANGUAGE_QUERY = 'lang';
export const DEFAULT_LANGUAGE = 'es' as const;
export type ALT_LANGUAGES = 'en';
export type SUPPORTED_LANGUAGES = typeof DEFAULT_LANGUAGE | ALT_LANGUAGES;
export type AVAILABLE_LANGUAGES =
  | [SUPPORTED_LANGUAGES]
  | [SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES];

export type WithAltLanguages<T, Keys extends string> = T & {
  [Prop in keyof T as Prop extends Keys ? `${Prop}_${ALT_LANGUAGES}` : never]?: T[Prop];
};

export type CloneCatalogOptions = {
  overwriteVisibility: boolean;
  overwriteStock: boolean;
};

export type CloneCatalogParams = {
  originSlug: string;
  targetSlug: string;
  cloneOptions: CloneCatalogOptions;
};

export interface ThinkionCatalog {
  id: string;
  name: string;
}

export interface MrcCatalog {
  id: string;
  name: string;
}

export interface MaxirestCatalog {
  id: number;
  node_type: string;
  nombre: string;
  descripc: string;
  orden: number;
  visible: number;
  articulos: MaxirestProducts[];
  subrubros: unknown[];
}

interface MaxirestProducts {
  id: number;
  node_type: string;
  descripc: string;
  nombre: string;
  precio: number;
  visible: number;
  itemsXRubro?: MaxirestItemsXRubro;
  itemsLibres?: MaxirestItemsLibre[];
}

interface MaxirestItemsLibre {
  id_art?: number;
  cantidad: number;
  id_articulos?: number;
}

type MaxirestItemsXRubro = {
  [key: string]: MaxirestItemsXRubroContent;
};

interface MaxirestItemsXRubroContent {
  ID_RUBRO: number;
  cantidad: number;
  id_articulos: number[];
}

export interface MaxirestOrder {
  order_id: string | number;
  store_id: string;
  order_time: string;
  estimated_pickup_time: string;
  delivery_fee: number;
  pick_up_code?: any;
  payment_method: string;
  customer_cash_payment_amount?: any;
  observa: string;
  delivery: boolean;
  customer: MaxirestOrderCustomer;
  total_price: number;
  products: MaxirestOrderProduct[];
  discount: MaxirestOrderDiscount[];
}

export interface MaxirestOrderDiscount {
  id: string;
  cod_externo: string;
  name: string;
  value: number;
}

interface MaxirestOrderCustomer {
  name: string;
  phone_number: string;
  street: string;
  surname?: string;
  mail?: string;
  number?: string;
  departament?: string;
}

export interface MaxirestOrderProduct {
  quantity: number;
  price: number;
  name: string;
  id: string;
  cod_externo: string;
  specification: string;
  attributes?: MaxirestOrderAttribute[];
}

export interface MaxirestOrderAttribute {
  quantity: number;
  id: string;
  cod_externo: string;
  name: string;
  price: number;
}

export type BulkEditEntity = 'products' | 'option-items';

export type ProductImportExcelData = {
  productId: string;
  itemType: 'product' | 'presentation';
  name: string;
  sku?: string;
  image: string;
  categoryName: string;
  categoryId: string;
  groupName?: string;
  description?: string;
  itemId?: string;
  presentationName?: string;
  presentationDescription?: string;
  presentationSelectionQty?: number | null; // Any number equals RangeType.FIXED; null equals RangeType.ANY
  price: number;
  priceDiscount?: number | null;
  stockQty: Product['stockQty'];
  status: 'ACTIVO' | 'INACTIVO';
  tagList: string;
  primaryTagName?: string;
  rowNumber?: number;
  isOptionItem: boolean;
  productRef: Product;
};

export type ProductImportExcelDataForValidation = Omit<ProductImportExcelData, 'tagList'> & {
  tagList: string[] | null;
};

export type ProductGridData = {
  id: string;
  name: string;
  image: string;
  categoryId: string;
  categoryName: string;
  groupName?: string;
  description?: string;
  hidden: boolean;
  presentationSelectionQty?: number | null;
  productTags: ProductTag[];
  primaryTag?: ProductTag;
  items: {
    itemType: 'product' | 'presentation';
    id: string;
    name: string;
    sku?: string;
    description: string | undefined;
    price: number;
    priceDiscount?: number;
    stockQty: Product['stockQty'];
    savingPrice?: boolean;
    savingPriceDiscount?: boolean;
    savingStockQty?: boolean;
  }[];
  searchableItemsPresentation: string;
  searchableItemsSku: string;
  searchableItemsPrice: string;
  searchableItemsPriceDiscount: string;
  searchableItemsStock: string;
  isOptionItem: boolean;
  productRef: Product;
};

export type ProductImportValidationResult = {
  rowNumber: number;
  productData: ProductImportExcelData;
  isValid: boolean;
  errors?: ErrorObject[];
};

export interface ThinkionOrder {
  details: ThinkionOrderDetails;
  discounts: unknown[];
  customer: ThinkionOrderCustomer;
  discount_details: ThinkionOrderDiscountdetail[];
  items: ThinkionOrderItem[];
  payments: ThinkionOrderPayment[];
}

export interface ThinkionOrderPayment {
  id_payment: number;
  is_discount: boolean;
  total: number;
}

export interface ThinkionOrderItem {
  ordering: number;
  id_group: number;
  can_discount: boolean;
  amount_parent: number;
  id_parent: number;
  id_product: number;
  notes?: string;
  amount: number;
  price: number;
  id_item: number;
  name: string;
}

interface ThinkionOrderDiscountdetail {
  id_discount?: number;
  name: string;
  total: number;
}

interface ThinkionOrderCustomer {
  id_customer: number;
  surname?: string;
  email?: string;
  name: string;
  address: ThinkionOrderAddress;
  company?: string;
  doc?: number;
  tel: string;
}

interface ThinkionOrderAddress {
  coords: ThinkionOrderCoords;
  city?: string;
  locality?: string;
  department?: string;
  number?: string;
  country?: string;
  route?: string;
  input: string;
}

interface ThinkionOrderCoords {
  lat: number;
  lng: number;
}

interface ThinkionOrderDetails {
  date_dispatch: string;
  sale_channel: string;
  notes: string;
  date_app: string;
  id_external_store: string;
  id_map: number;
  total: ThinkionOrderTotal;
  sale_icon: string;
  id_order: number | string;
  id_zone: number;
}

interface ThinkionOrderTotal {
  discount: number;
  delivery: number;
  debt: number;
}
export type BulkActionType =
  | 'price'
  | 'priceDiscount'
  | 'stockQty'
  | 'group'
  | 'status'
  | 'category'
  | 'delete';

export type MRCOrder = {
  access_token: string;
  idLocalmrc: string;
  idlocal: string;
  app: string;
  operador: string;
  idpedido: string;
  notes: string;
  registeredDate: string;
  pickup: boolean;
  isScheduled: boolean;
  deliveryDate: string;
  state: string;
  address: MRCAddress;
  discounts: MRCDiscount[];
  payment: MRCPayment;
  details: MRCDetail[];
  user: MRCUser;
};

export type MRCUser = {
  lastName: string;
  name: string;
  email: string;
  platform: string;
};

export type MRCDetail = {
  unitPrice: number;
  quantity: number;
  total: number;
  notes: string;
  subtotal: number;
  discount: number;
  product: MRCProduct;
  optionGroups: MRCOptionGroup[];
};

export type MRCOptionGroup = {
  integrationCode: string;
  name: string;
  options: MRCOption[];
};

export type MRCOption = {
  amount: number;
  quantity: number;
  integrationCode: string;
  name: string;
  id: string;
};

export type MRCProduct = {
  integrationCode: string;
  name: string;
  id: string;
};

export type MRCPayment = {
  total: number;
  shipping: number;
  shippingNoDiscount: number;
};

export type MRCDiscount = {
  amount: number;
  notes: string;
};

export type MRCAddress = {
  area: string;
  notes: string;
  corner: string;
  doorNumber: string;
  city: string;
  phone: string;
  street: string;
  coordinates: string;
  description: string;
};

export type NotificationTypes = 'inactive_account_payment_required' | 'mercadopagotoken_removed';
export type NotificationChannels = 'whatsapp' | 'email';

export type NotificationCollection = {
  slug: string;
  type: NotificationTypes;
  channels: NotificationChannels[];
  date: Date;
  customFields: Record<string, unknown>;
  sent?: boolean;
};
export type ZenriseDebtResponse = {
  status: string;
  message: string;
  data: ZenriseDebtData;
};

export type ZenriseDebtData = {
  daysRemaining: number;
  debt: number;
  total: number;
  priceCurrentPlan: number;
  subscription: SubscriptionInfo;
  slug: string;
};

export interface ZenriseReactivateSubscription {
  subscriptionId: number;
  collectPendingInvoice: boolean;
  extraDays: number;
  creditCard: boolean;
  paymentToken: ZenriseReactivateSubscriptionPaymentToken;
}
interface ZenriseReactivateSubscriptionPaymentToken {
  bin: string;
  token: string;
  lastFourDigits: string;
  cardDueDate: string;
}

export interface MercadopagoTokenResponse {
  success: boolean;
  message: string;
  data?: any;
}

export type PaymentLinkResponse = {
  paymentLink: string;
  id?: string;
};

export type UalaConfig = {
  user_name: string;
  client_id: string;
  client_secret_id: string;
  grant_type: string;
};

export type CfIpLocation = {
  country: string;
  city: string;
  continent: string;
  latitude: string;
  longitude: string;
  postalCode: string;
  metroCode: string;
  region: string;
  regionCode: string;
  timezone: string;
};

export type Campaign = {
  campaignId: string;
  id?: string;
  adSetId: string;
  adId: string;
  establishmentId: string;
  establishmentName: string;
  establishmentSlug: string;
  budget: number;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  comments?: string;
  notificationSent?: boolean;
};

export interface CampaignRequest {
  establishmentId: string;
  budget: number;
  activeDays: number;
  comments?: string;
  gender?: number[];
  ageMin?: number;
  ageMax?: number;
  categoryIds?: string[];
  paymentMethodId: string;
}

export interface CampaignGrid {
  createdAt: Date;
  adId: string;
  comments: string;
  endDate: Date;
  campaignId: string;
  adSetId: string;
  startDate: Date;
  establishmentId: string;
  budget: number;
  facebookCampaign: CampaignGridFacebook;
}

export interface CampaignGridFacebook {
  name: string;
  status: string;
  insights: CampaignGridFacebookInsights;
}

export interface CampaignGridFacebookInsights {
  spend: number;
  impressions: number;
  clicks: number;
  customConversionValue: number;
}
export type PricingError = {
  id: string;
  type: 'product' | 'presentation' | 'option-item';
  name: string;
  oldPrice: number;
  oldPriceDiscount: number;
  newPrice: number;
  newPriceDiscount: number;
  parentItem?: {
    id: string;
    name: string;
  };
};

export type ProductTag = {
  id: string;
  establishmentId: string;
  sortOrder: number;
  clonedFrom?: string;
  details: WithAltLanguages<
    {
      tag: string;
      color: string;
      created: Date;
      updated: Date;
    },
    'tag'
  >;
};
